/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";

import axios from "axios";

import { APIServiceClient } from "./api-service";

import type {
  Team,
  ContactFormRequestBody,
  ContactFormType,
  DefaultColor,
  BrandColor,
  TeamColor,
} from "./types/site-service";

export const cancelTokenSource = axios.CancelToken.source();

let siteServiceClient: SiteServiceClient | undefined;

/**
 * The SiteServiceClient class creates an Axios instance for making HTTP requests to the
 * User microservice API, with methods for authentication, profile management, and miscellaneous tasks.
 *
 * TODO: Add types for all data models used in this service.
 * TODO: Replace all uses of "any" type.
 */
class SiteServiceClient extends APIServiceClient {
  constructor() {
    super({
      baseURL: process.env.TPL_USERS_SERVICE,
      config: {
        cancelToken: cancelTokenSource.token,
        transformRequest: [
          (data, headers) => {
            headers.clear("Authorization");
            return data;
          },
        ],
      },
    });
  }

  theme() {
    return {
      getSiteDefaultColors: async (): Promise<DefaultColor[]> =>
        await this.instance.get("tpl-legacy/site-default-colors/", {
          withCredentials: false,
        }),
      getSiteBrandColors: async (): Promise<BrandColor[]> =>
        await this.instance.get("tpl-legacy/site-brand-colors/", {
          withCredentials: false,
        }),
      getTeamColors: async (teamIds: string[]): Promise<TeamColor[]> => {
        const params = new URLSearchParams();
        teamIds.forEach((id: string) => params.append("team_id", id));
        return await this.instance.get("tpl-legacy/site-organization-colors/", {
          params,
          withCredentials: false,
        });
      },
    };
  }

  teams() {
    return {
      fetchAll: async (): Promise<Team[]> =>
        await this.instance.get("auth/teams/", { withCredentials: false }),
    };
  }

  misc() {
    return {
      submitContactForm: async (
        data: ContactFormRequestBody,
        form: ContactFormType
      ) =>
        await this.instance.post(
          `${form === "contactUs" ? "contact_us/" : "work_with_us/"}`,
          { ...data, company: data.company ?? null }
        ),
    };
  }
}

const createSiteServiceClient = () => {
  return new SiteServiceClient();
};

export const getSiteService = () => {
  const _siteServiceClient = siteServiceClient ?? createSiteServiceClient();

  if (!siteServiceClient) siteServiceClient = _siteServiceClient;

  return _siteServiceClient;
};

/**
 * It creates a SiteService Client instance and returns it
 */
export const useSiteService = () => {
  const store = useMemo(() => getSiteService(), []);
  return store;
};

export type { TeamColor, BrandColor, DefaultColor };
