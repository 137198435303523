import camelCase from "lodash/camelCase";

const isArray = (a) => {
  return Array.isArray(a);
};

const isObject = (o) => {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};

export const keysToCamel = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[camelCase(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

export const getObjectSubset = (
  obj: Record<string, unknown>,
  keys: string[]
) => {
  return keys.reduce((subset, key) => {
    if (key in obj) {
      subset[key] = obj[key];
    }

    return subset;
  }, {});
};

export const hasMatchingKey = (
  obj: Record<string, unknown>,
  keys: string[]
) => {
  return Object.keys(obj).some((key) => keys.includes(key));
};
