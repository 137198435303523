import {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useMemo,
} from "react";

import useSWRImmutable from "swr/immutable";

import { getSiteService } from "api/site-service";

import type { Fetcher } from "swr";
import type { Team } from "utils/types/teams";

interface Props extends PropsWithChildren {
  fallback?: Team[];
}

interface TeamsContextProps {
  teams: Team[];
  currentTeam: Team;
  setCurrentTeam: (data: Team) => void;
  setCurrentTeamBySlug: (slug: string) => void;
  getTeamByKey: (value: string, key: keyof Team) => Team;
}

// Creating the teams context
export const TeamsContext = createContext<TeamsContextProps>({
  teams: [],
  currentTeam: null,
  setCurrentTeam: null,
  setCurrentTeamBySlug: null,
  getTeamByKey: null,
});

const fetcher: Fetcher<Team[]> = (url: string) =>
  getSiteService().get<Team[]>(url);

export const TeamsContextProvider = ({ children, fallback }: Props) => {
  const [currentTeam, setCurrentTeam] = useState<Team>(null);

  const { data } = useSWRImmutable("auth/teams/", fetcher);

  const teams = useMemo(
    () =>
      data?.map((t) => ({ ...t, slug: t.shortName.toLowerCase() })) || fallback,
    [data, fallback]
  );

  const setCurrentTeamBySlug = (slug: string) => {
    const team = teams.find((t) => t.shortName.toLowerCase() === slug);

    setCurrentTeam(team);
  };

  const getTeamByKey = (value: string, key: keyof Team) => {
    return teams?.find((t) => t[key] === value);
  };

  return (
    <TeamsContext.Provider
      value={{
        teams,
        currentTeam,
        setCurrentTeam,
        setCurrentTeamBySlug,
        getTeamByKey,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

// Make useTeamsContext Hook to easily use our context throughout the application
export const useTeamsContext = () => {
  return useContext(TeamsContext);
};
