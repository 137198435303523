import axios, { CreateAxiosDefaults, type AxiosInstance } from "axios";
import jwt from "jwt-decode";
import router from "next/router";
import { getSession, signOut } from "next-auth/react";

import { resetAnalytics } from "lib/segment";
import { keysToCamel } from "utils/helpers/objectUtils";

import type { DecodedJWT } from "next-auth";

/**
 * The function sets the default access token for axios.
 * @param {string} token - The value parameter is a string that represents the access token.
 */
export const setAccessToken = (token: string): void => {
  axios.defaults.headers.common.Authorization = token
    ? `Bearer ${token}`
    : undefined;
};

/**
 * The CustomError class extends the Error class and creates a custom error object with an error
 * description.
 * @extends Error
 * @property {string} errorDescription - The errorDescription property is a string that represents the
 * description of the error.
 * @constructor
 * @param {string} message - The message parameter is a string that represents the error message.
 * @param {string} errorDescription - The errorDescription parameter is an optional string that
 * represents the description of the error.
 */
export class CustomError extends Error {
  errorDescription: string;
  constructor(message: string, errorDescription?: string) {
    super(message);
    this.errorDescription = errorDescription;
  }
}

/**
 * The APIServiceClient class creates an Axios instance for making HTTP requests to the
 * relevant backend service.
 * @property {AxiosInstance} instance - The instance property is an Axios instance that is used to make
 * HTTP requests to the backend service.
 * @returns an instance of the APIServiceClient class.
 */
export class APIServiceClient {
  protected instance: AxiosInstance;

  /**
   * The constructor method creates an instance of the APIServiceClient class.
   * @constructor
   * @param {Object} params - The params parameter is an object that contains the following properties:
   * @param {string} params.baseURL - The baseURL property is a string that represents the base URL of the
   * backend service.
   * @param {string} params.accessToken - The accessToken property is an optional string that represents
   * the access token.
   * @param {CancelToken} params.cancelToken - The cancelToken property is an optional CancelToken that
   * represents the token to cancel the request.
   * @param {boolean} params.disabledKeysToCamel - The disabledKeysToCamel property is an optional boolean
   * value that indicates whether or not the keysToCamel call is disabled.
   */
  constructor({
    baseURL,
    accessToken,
    config,
    disabledKeysToCamel,
  }: {
    baseURL: string;
    accessToken?: string;
    config?: CreateAxiosDefaults;
    disabledKeysToCamel?: boolean;
  }) {
    accessToken && setAccessToken(accessToken);

    this.instance = axios.create({
      baseURL: baseURL,
      cancelToken: config?.cancelToken || axios.CancelToken.source().token,
      ...config,
    });

    this.instance.interceptors.request.use(async (request) => {
      if (
        "Authorization" in request.headers &&
        request.headers.Authorization === undefined
      ) {
        request.headers.clear("Authorization");
      }

      if ("withCredentials" in request) {
        if (request.withCredentials) {
          if (!axios.defaults.headers.common.Authorization) {
            await this.getAccessToken();
          }

          if (!request.headers.Authorization) {
            request.headers.Authorization =
              axios.defaults.headers.common.Authorization;
          }

          delete request.withCredentials;

          const accessToken = request.headers.Authorization?.toString()
            ?.split(" ")
            ?.at(-1);

          if (accessToken) {
            const decodedToken: DecodedJWT = jwt(accessToken);

            if (Date.now() > decodedToken.exp * 1000) {
              const newAccessToken = await this.getAccessToken();
              request.headers.setAuthorization(`Bearer ${newAccessToken}`);
            }
          }

          return request;
        } else {
          request.headers.clear("Authorization");
          delete request.withCredentials;
        }
      }

      return request;
    });

    this.instance.interceptors.response.use(
      (response) => {
        return disabledKeysToCamel ? response.data : keysToCamel(response.data);
      },
      async (error) => {
        const originalRequest = error.config;

        if (error.response) {
          const { response } = error;

          const { data } = response || {};

          // If the access token is invalid, get a new one and retry the request
          if (response?.status === 403 && data.code === "token_not_valid") {
            /**
             * Get a new access token and retry the request
             */
            const newAccessToken = await this.getAccessToken();

            if (newAccessToken) {
              originalRequest._retry = true;
              originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

              return this.instance(originalRequest);
            } else {
              /**
               * If the new access token is not available, logout and redirect the user to the login page
               * with an error message
               */
              const data = await signOut({
                redirect: false,
                callbackUrl: `/home/login?error=${encodeURIComponent(
                  "Session expired. Log back in to continue."
                )}`,
              });

              if (typeof window !== "undefined") {
                router.push(data.url);
                resetAnalytics();
              }
            }
          }

          const errorFromBackend =
            (data && data.detail) ||
            (data && data.email && data.email[0]) ||
            (data && data.password && data.password[0]) ||
            (Array.isArray(data) && data[0]) ||
            (data && Array.isArray(data.error) && data.error[0]) ||
            response?.statusText;

          if (
            Array.isArray(errorFromBackend?.error) &&
            errorFromBackend?.error[0]
          ) {
            throw new CustomError(errorFromBackend, errorFromBackend?.error[0]);
          } else if (data?.error?.E301) {
            throw new CustomError(data?.error?.E301);
          }

          throw new CustomError(errorFromBackend);
        }

        throw error;
      }
    );
  }

  /**
   * The function is an asynchronous method that makes a GET request to an endpoint and returns a promise
   * of type T.
   * @param {string} endpoint - The `endpoint` parameter is a string that represents the URL or path of
   * the API endpoint that you want to make a GET request to. It specifies the location where the server
   * should handle the request and return the response data.
   * @param {boolean} [withCredentials] - The `withCredentials` parameter is a boolean value that
   * indicates whether or not cross-site Access-Control requests should be made using credentials such as
   * cookies, authorization headers, or TLS client certificates. If `withCredentials` is set to `true`,
   * the request will include any cookies or authorization headers associated with the
   * @returns a Promise of type T.
   */
  async get<T>(endpoint: string, withCredentials = false): Promise<T> {
    return await this.instance.get(endpoint, { withCredentials });
  }

  async getWithApiKey<T>(endpoint: string, key): Promise<T> {
    return await this.instance.get(endpoint, {
      headers: {
        "x-api-key": key,
      },
    });
  }

  async getWithToken<T>(endpoint: string, accessToken: string): Promise<T> {
    return await this.instance.get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  /**
   * The function is an asynchronous method that sends a POST request to an endpoint with optional data
   * and credentials.
   * @param {string} endpoint - The endpoint parameter is a string that represents the URL or path where
   * the HTTP POST request will be sent to. It typically specifies the server-side endpoint or API route
   * that will handle the request.
   * @param {unknown} [data] - The `data` parameter is an optional parameter of type `unknown`. It
   * represents the data that will be sent in the request body when making a POST request to the
   * specified `endpoint`. The type `unknown` means that the data can be of any type.
   * @param {boolean} [withCredentials] - The `withCredentials` parameter is a boolean value that
   * determines whether or not cross-site Access-Control requests should be made using credentials such
   * as cookies, authorization headers, or TLS client certificates. If `withCredentials` is set to
   * `true`, the request will include credentials; if set to `false`,
   * @returns a Promise of type T.
   */
  async post<T>(
    endpoint: string,
    data?: unknown,
    withCredentials = false
  ): Promise<T> {
    return await this.instance.post(endpoint, data, { withCredentials });
  }

  /**
   * The function is an asynchronous method that sends a PUT request to an endpoint with optional data
   * and credentials.
   * @param {string} endpoint - The endpoint parameter is a string that represents the URL or path where
   * the data will be sent to or retrieved from. It typically specifies the location of a specific
   * resource on a server.
   * @param {unknown} [data] - The `data` parameter is an optional parameter that represents the data
   * that you want to send in the PUT request. It can be of any type (`unknown`) as specified in the
   * function signature.
   * @param {boolean} [withCredentials] - The `withCredentials` parameter is a boolean value that
   * indicates whether or not cross-site Access-Control requests should be made using credentials such as
   * cookies, authorization headers, or TLS client certificates. When set to `true`, the request will
   * include credentials; when set to `false`, the request will not include
   * @returns a Promise of type T.
   */
  async put<T>(
    endpoint: string,
    data?: unknown,
    withCredentials = false
  ): Promise<T> {
    return await this.instance.put(endpoint, data, { withCredentials });
  }

  /**
   * The function is an asynchronous method that sends a DELETE request to a specified endpoint and
   * returns a promise with the response data.
   * @param {string} endpoint - The endpoint parameter is a string that represents the URL or path of the
   * resource that you want to delete. It specifies the location of the resource on the server.
   * @param {boolean} [withCredentials] - The `withCredentials` parameter is a boolean value that
   * indicates whether or not cross-site Access-Control requests should be made using credentials such as
   * cookies, authorization headers, or TLS client certificates. If `withCredentials` is set to `true`,
   * the request will include credentials; if set to `false`,
   * @returns The delete method is returning a Promise of type T.
   */
  async delete<T>(endpoint: string, withCredentials = false): Promise<T> {
    return await this.instance.delete(endpoint, { withCredentials });
  }

  /**
   * The function `getAccessToken` retrieves and returns an access token, either from the current session
   * or by setting a new one.
   * @returns {Promise<string>} a Promise that resolves to a string.
   */
  async getAccessToken(): Promise<string> {
    const token = (await getSession())?.accessToken;

    setAccessToken(token);

    return token;
  }
}
